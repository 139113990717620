//note that you must have this code in gatsby-browser.js AND gatsby-ssr.js
import React from 'react'

import AutoScrollHash from './src/components/AutoScrollHash/AutoScrollHash'
import DefaultHelmet from './src/components/DefaultHelmet/DefaultHelmet'
import ScrollTopButton from "./src/components/ScrollTopButton/ScrollTopButton"

import "./src/bootstrap-grid-4.3.1.min.css"
import "./src/index.scss"

export const wrapPageElement = ({element}) => {
  return (
    <React.Fragment>
      <DefaultHelmet/>
      
      <ScrollTopButton/>

      <AutoScrollHash>
        {element}
      </AutoScrollHash>
    </React.Fragment>
  )
}