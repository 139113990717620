import React from "react"
import { Helmet } from "react-helmet"

const DefaultHelmet = () => {
  return (
    <Helmet>
      <meta charset="utf-8" />
      <title>Harry Li</title>
      <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1"/>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="description" content="I am a full stack web developer and data visualization creator at MIT Lincoln Laboratory. I love to code solutions for problems I see around me."/>
      <meta name="keywords" content="harry, li, harryxli, visualization, data, developer, resume, MIT, Lincoln, Laboratory, web, full, stack"/>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />

      <meta property="og:image" content="https://harryxli.com/harry-li.jpg"/>
      <meta property="og:site_name" content="harryxli"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content="Harry Li"/>
      <meta property="og:url" content="https://harryxli.com"/>
      <meta property="og:description" content="I am a full stack web developer and data visualization creator at MIT Lincoln Laboratory. I love to code solutions for problems I see around me."/>
      
    </Helmet>
  )
}

export default DefaultHelmet