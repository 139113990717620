import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

import isBrowser from "constants/isBrowser";
import smoothScroll from "utils/smoothScroll";

import "./scrollToTop.css";

export default function ScrollTopButton() {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    isBrowser && (
      window.onscroll = function() {
        const element = document.getElementById("scroll-top-button");
        if(element) {
          setShow(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)
        }
      }
    );
  }, [])

  return (
    <button id="scroll-top-button" className={show?"show":""} onClick={e => smoothScroll("___gatsby")}>
      <FontAwesomeIcon icon={faAngleUp}/>
    </button>
  );
}
