import React from 'react'
import isBrowser from '../../constants/isBrowser';

//this is primarily used to auto scroll after hte page has loaded, ie when a user clicks on the ISP or a menu section
class AutoScrollHash extends React.Component {
  componentDidMount() {
    scrollIntoViewHash()
    isBrowser && window.addEventListener("hashchange", scrollIntoViewHash)
  }

  componentWillUnmount() {
    isBrowser && window.removeEventListener("hashchange", scrollIntoViewHash)
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    );
  }
}


export default AutoScrollHash;

export function scrollIntoViewHash() { //see if we need to scroll to an element by id
  const id = isBrowser && window.location.hash.slice(1) //remove leading hashtag
  if(id) { //if there is an id
    const element = document.getElementById(id); //see if this element exists
    if(element) { //if this element exists
      element.scrollIntoView({behavior:"smooth"}) //scroll to it
    }
  }
}
